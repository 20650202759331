import React from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import {
	rootRelativeDynaSitePath,
	rootRelativeMobileSitePath,
	isOnServer,
} from '~/global/global.constants';

const mobileFooterRenderer = observer(({ tab }) => {
	const {
		links,
	} = tab;
	const classes = tab.classes ? tab.classes : '';

	const renderIcon = (linkIcon) => {
		if (linkIcon) {
			return <img src={`/img/ico/${linkIcon}.svg`} alt="" width="32" />;
		}
		return false;
	};

	const renderLink = (link) => {
		const linkURL = (() => {
			if (link.noMobile) {
				return `${rootRelativeDynaSitePath}/${link.url}`;
			}
			return link.url.includes('http') || link.url.includes('tel') || link.url.includes('mailto') ? link.url : `${rootRelativeMobileSitePath}/${link.url}`;
		});
		const linkTarget = link.url.includes('http') ? '_blank' : null;
		return (
			<a className={'tw-no-underline u-focusOutline'} href={linkURL()} target={linkTarget}>
				{renderIcon(link.icon)}
				{link.copy}
			</a>
		);
	};

	const renderChildren = (link) => {
		const linkJS = toJS(link);
		return linkJS.children;
	};

	return (
		<ul className={`no-bullet ${classes}`}>
			{links.map((link, index) => {
				const {
					isButton = false,
				} = link;
				return (
					<li key={index}>
						{!isButton && renderLink(link)}
						{/* If rendering on server just render out the button text */}
						{isOnServer && link.children && renderChildren(link)}
						{/* If not on the server render out the button with click */}
						{!isOnServer && link.children}
					</li>
				);
			})}
		</ul>
	);
});

export {
	mobileFooterRenderer
};
